import React from 'react';
import * as styles from './gsProductPrice.module.scss';

import { gsCurrencyPipe } from "../../helper/gsCurrencyPipe";

const GsProductPrice = ( { className = '', product, showFromTo = false } ) => {
    if (!product) {
        return null;
    }

    let priceLabel = gsCurrencyPipe(product.priceRangeV2.minVariantPrice.amount);

    if (showFromTo && product.priceRangeV2.minVariantPrice.amount != product.priceRangeV2.maxVariantPrice.amount) {
        priceLabel += " bis " + gsCurrencyPipe(product.priceRangeV2.maxVariantPrice.amount);
    }

    return <div className={className + " " + styles.price}>{priceLabel}</div>
};

export default GsProductPrice;
