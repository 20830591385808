import React from 'react';
import * as styles from './more-link.module.scss';
import Link from "gatsby-link";
import ChevronRightSVG from "../../icons/fontawesome/light/chevron-right.svg";

const MoreLink = ( { to = '/', children = 'Mehr', style='absolute' } ) => {

    let additionalCSS = "";
    if (style === 'absolute') {
        additionalCSS = styles.absolute + " ";
    }

    return <Link to={to} className={styles.more + " " + additionalCSS + " more align-items-center d-flex"}>
        <ChevronRightSVG className={"svgFill me-1"} />
        {children}
    </Link>;
};

export default MoreLink;
