import React from 'react';
import { Link } from "gatsby";
import ChevronRightSVG from "../../icons/fontawesome/light/chevron-right.svg";

const GsArrowLink = ( { to = "", children, className = "" }) => {
    return <Link to={to} >
        <a className={"d-block d-flex align-items-center " + className}>
            <ChevronRightSVG className={"me-1 svgFill"} />
            { children }
        </a>
    </Link>
};

export default GsArrowLink;
