import React from 'react';
import Layout from "../page-components/layout/layout";
import { graphql, Link } from "gatsby";
import GsProductHeaderImage from "../page-components/product/gsProductHeaderImage";
import GsProductPrice from "../page-components/product/gsProductPrice";
import MoreLink from "../page-components/buttons/more-link";
import GsArrowLink from "../page-components/buttons/gsArrowLink";

const ProductsPage = ( { data }) => {

    const products = data.allShopifyProduct.nodes;

    return <Layout>
        <div className="container">
            <div className="row">
                <div className="col-12 py-5">
                    <h1>Produkte</h1>

                    {
                        products.map( (product, i) => {

                            return <div key={product.id} className={"row bg-white my-3 align-items-center"}>
                                <div className="col-4">
                                    <GsProductHeaderImage product={product} />
                                </div>

                                <div className="col-8">
                                    <strong className={"mb-2"}>{product.title}</strong>

                                    <p>
                                        <i>{product.productType}</i>
                                    </p>

                                    <p>
                                        <GsProductPrice product={product} />
                                    </p>

                                    <GsArrowLink to={"/produkt/" + product.handle} className={"mt-1"}>
                                        Details
                                    </GsArrowLink>

                                </div>
                            </div>
                        })
                    }

                </div>
            </div>
        </div>
    </Layout>
};

export default ProductsPage;

export const query = graphql`
    query PRODUCT_LIST_PAGE {
        allShopifyProduct {
            nodes {
              title
              handle
              productType
              images {
                id
                originalSrc
              }
              priceRangeV2 {
                maxVariantPrice {
                  amount
                }
                minVariantPrice {
                  amount
                }
              }
            }
          }
    }
`;
