import React from 'react';
import * as styles from "./gsProductHeaderImage.module.scss";

import { GatsbyImage } from "gatsby-plugin-image";

const GsProductHeaderImage = ( {
                                   className = "",
                                   product,
                                   availableVariants = null,
                                   onClick = () => { }
                                } ) => {

    if (!product) {
        return null;
    }

    if (!product.images || product.images.length === 0) {
        return null;
    }

    let imageUrl = null;
    let image = product.images[0];

    if (image.originalSrc) {
        imageUrl = image.originalSrc;
    }

    if (availableVariants) {
        const images = new Set(availableVariants.map(a => a.image?.id));

        // unique variant exists
        if (images.size === 1) {

            const variant = availableVariants[0];

            if (variant.image?.originalSrc) {
                imageUrl = variant.image.originalSrc;
            }

        }
    }

    if (!imageUrl) {
        return null;
    } else {
        return <img src={imageUrl} onClick={onClick} className={className + " " + styles.img} />
    }

    return <GatsbyImage onClick={onClick} className={className} image={image.localFile.childImageSharp.gatsbyImageData} />
}

export default GsProductHeaderImage;
